@import "../../../styles/variables";

.mobileButton {
  width: 3em;
  height: 3em;
  padding: .55em;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  span {
    position: relative;
    width: 100%;
    height: 3px;
    border-radius: 2px;
    background-color: #fff;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.11);
  }
}

@media screen and (max-width: $screen-m) {
  .mobileButton {
    width: 2.25em;
    height: 2.25em;
    padding: .35em;
  }
}