@import "icons/icons-font";
@import "variables";

/* titillium-web-regular - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/titillium-web-v6-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web Regular'), local('TitilliumWeb-Regular'),
  url('fonts/titillium-web-v6-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/titillium-web-v6-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/titillium-web-v6-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('fonts/titillium-web-v6-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/titillium-web-v6-latin-regular.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-600 - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/titillium-web-v6-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web SemiBold'), local('TitilliumWeb-SemiBold'),
  url('fonts/titillium-web-v6-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/titillium-web-v6-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/titillium-web-v6-latin-600.woff') format('woff'), /* Modern Browsers */
  url('fonts/titillium-web-v6-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/titillium-web-v6-latin-600.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-700 - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/titillium-web-v6-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web Bold'), local('TitilliumWeb-Bold'),
  url('fonts/titillium-web-v6-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/titillium-web-v6-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/titillium-web-v6-latin-700.woff') format('woff'), /* Modern Browsers */
  url('fonts/titillium-web-v6-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/titillium-web-v6-latin-700.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  text-decoration: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.segment {
  padding-left: 15% !important;
  padding-right: 15% !important;
  position: relative !important;
  left: 0 !important;
}

@media screen and (max-width: $screen-l) {
  .segment {
    padding-left: 8em !important;
    padding-right: 8em !important;
  }
}

@media screen and (max-width: $screen-m) {
  .segment {
    padding-left: 3em !important;
    padding-right: 3em !important;
  }
}

@media screen and (max-width: $screen-s) {
  .segment {
    padding-left: 2em !important;
    padding-right: 2em !important;
  }
}

@media screen and (max-width: $screen-xs) {
  .segment {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
}
