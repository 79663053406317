@import "../../../styles/variables";

.TransparentButton {
  font-size: 1.2em;
  color: $font-color-white;
  font-weight: $font-weight-bold;
  padding: 10px 2%;
  //margin: 0 5px;
  margin: 2px;
  border: 1px solid transparent;
  border-radius: $button-border-radius;
  &:hover {
    border: 1px solid #fff;
  }
}