@import "variables";

.App {
  margin: 0 auto;
  font-size: $initial-font-size;
  font-family: 'Titillium Web', sans-serif;

  button {
    cursor: pointer;
  }

  .wrapper {
    overflow: hidden;
  }
}

::-webkit-scrollbar {
  width: 9px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba(129, 145, 197, 0.84);
  opacity: .5;
  border-radius: 4px;

  &:hover {
    opacity: 1;
  }
}

::-webkit-scrollbar-track {
  background: rgba(223, 241, 241, 0.51);
  opacity: .2;

  &:hover {
    opacity: .4;
  }
}
