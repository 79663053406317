@import "../../styles/variables";

.Footer {
  position: relative;
  padding-top: 15px;
  padding-bottom: 0;
  transition: padding-bottom .2s ease;

  .logos-row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: wrap;
    margin-bottom: 1.5em;

    img { margin: 0 1em; }

    .visaVerif { width: 90px; }
    .visa { width: 78px; }
    .mc { width: 165px; }
    .mcSc { width: 92px; }
    .pci { width: 106px; }
  }

  .links-row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20%;
    margin-bottom: 1.75em;

    .left-link, .right-link {
      width: 50%;

      &:not(:last-child) {
        border-right: 2px solid #fff;
      }

      & > * {
        color: $font-color-white;
        text-decoration: underline;
        font-size: 1.1em;
        padding: 0 1em;
      }
    }

    .left-link {
      text-align: right;
    }
  }

  .info-row {
    text-align: center;
    font-size: 1em;
    line-height: 1.557;
    color: $font-color-white;
    padding: 10px 20% 50px;
  }

  &.with-cookies {
    padding-bottom: 50px !important;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    top: -15px;
    z-index: -1;
    background-image: linear-gradient(90deg, #3a7fe9, #2b2cb0);
    height: 250%;
  }
}

@media screen and (max-width: $screen-m) {
  .Footer {
    .links-row {
      padding: 0 2em;

      * {
        font-size: 1em !important;
      }
    }

    .info-row {
      margin: 2em 0;
      padding: 0 .5em !important;
    }
  }
}

@media screen and (max-width: $screen-s) {
  .Footer {
    .logos-row {
      .visaVerif {
        width: 75px !important;
      }

      .visa {
        width: 63px !important;
      }

      .mc {
        width: 145px !important;
      }

      .mcSc {
        width: 77px !important;
      }

      .pci {
        width: 96px !important;
      }
    }

    .links-row {
      padding: 0 1em;
      display: block;

      .left-link, .right-link {
        border-right: none !important;
        width: 100%;
        text-align: center;
      }

      * {
        font-size: .95em !important;
        line-height: 1.25em;
        margin-top: .3em;
      }
    }
  }
}
