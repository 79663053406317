@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?pvk1f0');
  src:  url('../fonts/icomoon.eot?pvk1f0#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?pvk1f0') format('truetype'),
  url('../fonts/icomoon.woff?pvk1f0') format('woff'),
  url('../fonts/icomoon.svg?pvk1f0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cards:before {
  content: "\e900";
}
.icon-cart:before {
  content: "\e901";
}
.icon-gears:before {
  content: "\e902";
}
.icon-money-world:before {
  content: "\e903";
}
.icon-protection:before {
  content: "\e904";
}
.icon-speed:before {
  content: "\e905";
}
