@import '../../styles/variables';

.header {
  padding-right: 0 !important;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row;
  width: 100%;
  margin-top: 2em;
  position: relative;
  z-index: 10;
}
.header-logo {
  height: 55px;
  position: relative;
}
.nav-holder {
  display: flex;
  align-self: center;
  justify-content: flex-start;
  padding-left: 30px;
  overflow-x: auto;
  width: 100%;

  &::-webkit-scrollbar {
    width: 9px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(129, 145, 197, 0.84);
    border-radius: 4px;
    opacity: .5;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  a {
    padding: .75em 1em;
  }
}
.login-btn-holder {
  height: 100%;
  position: relative;
  margin: 0;
  text-align: right;
  a {
    position: relative;
    padding: .5em 1.25em;
    transition: .2s background-color ease;
    &:hover {
      background-color: rgba(241, 240, 240, 0.08);
    }
  }
}

.mobile-nav-holder {
  background-image: linear-gradient(to right, #3a7ce6, #3976e2, #323dbf, #3136bc);
  position: absolute;
  width: 100%;
  padding: .5em 1em;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  .login-btn-holder {
    width: auto;
  }
}

.mobile-nav-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

@media screen and (max-width: $screen-xl) {
  .header-logo {
    height: 40px;
  }
}

@media screen and (max-width: $screen-l) {
  .header {
    margin-top: 1.5em;
  }
  .nav-holder {
    width: initial;
    padding-right: 1em;
    a {
      padding: .6em;
      font-size: 1em;
      height: 100%;
    }
  }
}

@media screen and (max-width: $screen-m) {
  .header {
    justify-content: space-between;
    margin-top: 0;
    padding: .2em;
  }
  .header-logo {
    width: 5em;
    height: 3em;
  }
  .login-btn-holder {
    a {
      font-size: .85em;
      padding: .25em .75em;
    }
  }
  .nav-holder {
    padding: 0 !important;

    a {
      padding: .6em .6em;
    }
  }
  .mobile-nav-holder {
  }
}

@media screen and (max-width: $screen-s) {
  .header-logo {
    height: 3em;
  }
  .login-btn-holder {
    a {
      font-size: .85em;
      padding: .25em .75em;
    }
  }
  .nav-holder {
    padding: 0;
    a {
      padding: .6em .45em;
      font-size: .85em;
    }
  }
  .mobile-nav-holder {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media screen and (max-width: $screen-xs) {
  .header {
    padding: 1em 5%;
  }
  .mobile-nav-holder {
    padding: 1em .5em;
  }
  .nav-holder {
    padding: 0;
    a {
      padding: .6em .3em;
      font-size: .7em;
    }
  }
}

